import "./App.css";

import datagre from "./data.json";

function App() {
	return (
		<div className="customtable">
			<div className="innerbox">
				<div className="headingtable">
					<div className="custablecol">
						<h5>Application Number</h5>
					</div>
					<div className="custablecol">
						<h5>Description</h5>
					</div>
					<div className="custablecol">
						<h5>Status</h5>
					</div>
					<div className="custablecol">
						<h5>Date</h5>
					</div>
					<div className="custablecol">
						<h5>Request Letter</h5>
					</div>
					<div className="custablecol">
						<h5>Admin Response</h5>
					</div>
				</div>
				{datagre.map((e) => {
					return (
						<div className="cusrow">
							<div className="accno">{e._aid}</div>
							<div className="gre">
								{e.gr.map((e) => {
									return (
										<div className="grenested">
											<div className="custablecol">
												<span>{e.gt}</span>
											</div>
											<div className="custablecol">
												<span>{e.st}</span>
											</div>
											<div className="custablecol">
												<span>{e.d.$date.slice(0, 10)}</span>
											</div>
											<div className="custablecol">
												<a
													href=""
													style={{ textDecoration: "none" }}
													className="anchor"
												>
													Download
												</a>
											</div>
											<div className="custablecol">
												<button className="btn1">Comment</button>
												<button className="btn2">Edit</button>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default App;
